import React, { FC, useCallback, useRef, useState } from "react";
import { DatePicker } from "@mui/lab";
import { Button } from "@likemagic-tech/sv-magic-library";
import { formatDate } from "../../../../utils/timezoned-date";
import { ScheduleOutlined } from "@mui/icons-material";
import { LocalizationDatePicker } from "../../../../components/language-selector/localization-date-picker";
import { QuickButtonsToolbar } from "./due-modal";
import { SubtasksForm } from "../task-modal";
import { useProperty } from "../../../../hooks/use-property";
import { useTheme } from "@mui/material";

interface SubtasksDueDateProps {
  subtask: SubtasksForm;
  setFieldValue: (field: string, value: any) => void;
  index: number;
  disabled: boolean;
}

export const SubtasksDueDate: FC<SubtasksDueDateProps> = ({
  subtask,
  setFieldValue,
  index,
  disabled
}) => {
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const { selectedProperty } = useProperty();

  const QuickButtonsToolbarCallBack = useCallback(
    (props: any, onAccept: any) => <QuickButtonsToolbar {...props} onAccept={onAccept} />,
    []
  );

  return (
    <LocalizationDatePicker>
      <DatePicker
        onClose={() => setOpen(false)}
        open={open}
        disableOpenPicker
        showToolbar
        PopperProps={{
          placement: "auto",
          anchorEl: anchorRef.current
        }}
        disabled={disabled}
        value={subtask.dueDate || ""}
        onAccept={(date: Date | null) => {
          date && setFieldValue(`subtasks.[${index}].dueDate`, date.toISOString());
          setFieldValue(`subtasks.[${index}].isEdit`, true);
        }}
        ToolbarComponent={(props) =>
          QuickButtonsToolbarCallBack(props, (value: any) => {
            setFieldValue(`subtasks.[${index}].dueDate`, value.toDateString());
            setFieldValue(`subtasks.[${index}].isEdit`, true);
            setOpen(false);
          })
        }
        onOpen={() => setOpen}
        renderInput={({ inputRef }) => (
          <div ref={inputRef}>
            <Button
              variant="ghost"
              size="medium"
              onClick={(event) => {
                anchorRef.current = event.currentTarget;
                setOpen(true);
              }}
              startIcon={
                !subtask.dueDate && (
                  <ScheduleOutlined sx={{ color: theme.palette.grey[700], mr: -1.5 }} />
                )
              }
              sx={{ minWidth: "auto", p: theme.spacing(1, 1, 1, 1) }}
              disabled={disabled}
            >
              {subtask.dueDate
                ? formatDate(subtask.dueDate, selectedProperty?.details?.timeZone)
                : ""}
            </Button>
          </div>
        )}
        onChange={() => {}}
      />
    </LocalizationDatePicker>
  );
};
