import React, { FC } from "react";
import { DatePicker } from "@mui/lab";
import { TextField, Grid } from "@mui/material";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { LocalizationDatePicker } from "src/components/language-selector/localization-date-picker";
import { isValid } from "date-fns";
import { getTime } from "@likemagic-tech/sv-magic-library";
interface DateTimePickerInterface {
  date?: Date | undefined;
  setDate: (value: Date) => void;
  setTime: (value: Date) => void;
  time?: Date;
  label: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  minimumTime?: string;
  dateError?: string;
}

const DateTimePicker: FC<React.PropsWithChildren<DateTimePickerInterface>> = ({
  time,
  date,
  setTime,
  setDate,
  label,
  disabled,
  minDate,
  maxDate,
  minimumTime,
  dateError = undefined
}) => {
  const { t } = useTranslationWrapper();

  const handleTimeChange = (time: string) => {
    if (time && date) {
      const [hours, minutes] = time.split(":").map(Number);
      const temp = new Date(date);
      temp.setHours(hours);
      temp.setMinutes(minutes);
      setTime(temp);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDate(date);
    }
  };

  const isSameDate = date && minDate && date.toDateString() === minDate.toDateString();

  return (
    <LocalizationDatePicker>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            PopperProps={{
              placement: "auto"
            }}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            label={label}
            value={date || ""}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                helperText={dateError ?? ""}
                error={!!dateError}
                InputLabelProps={{
                  sx: {
                    opacity: 0.5
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={disabled || !date || !isValid(date)}
            fullWidth
            value={getTime(time)}
            label={t("labels__time")}
            type="time"
            onChange={(e) => handleTimeChange(e.target.value)}
            placeholder=""
            inputProps={{
              step: 60,
              min: isSameDate && minimumTime ? minimumTime : undefined
            }}
            error={isSameDate && minimumTime ? minimumTime > getTime(time) : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </LocalizationDatePicker>
  );
};

export default DateTimePicker;
