import React, { FC, useState } from "react";
import { Input } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { StatusButtons } from "./status-buttons";
import { Description } from "./description";
import { AttachmentsForm } from "./attachments";
import { DueDate } from "./due-date";
import { Assign } from "./assign";
import { Link } from "./link";
import { Subtasks } from "./subtasks";
import { PrioritySelect } from "./priority-select";
import { FormikErrors, FormikTouched } from "formik";
import { SubtasksForm, TaskFormValues } from "../task-modal";

interface TaskFormNewProps {
  handleChange: (e: any) => void;
  setFieldValue: (field: string, value: any) => void;
  values: TaskFormValues;
  errors: FormikErrors<TaskFormValues>;
  touched: FormikTouched<TaskFormValues>;
  subtasks: SubtasksForm[];
}

export const TaskForm: FC<TaskFormNewProps> = ({
  handleChange,
  values,
  setFieldValue,
  errors,
  touched,
  subtasks
}) => {
  const { t } = useTranslationWrapper();

  const [isWysiwygVisible, setIsWysiwygVisible] = useState(false);

  return (
    <Box sx={{ p: 1, gap: 2, display: "flex", flexDirection: "column" }}>
      <Input
        variant="standard"
        sx={{ width: "100%", mt: 2 }}
        id="title"
        name="title"
        placeholder={t("labels__add_task_title")}
        onChange={handleChange}
        value={values.title}
        error={touched.title && errors.title}
      />
      <StatusButtons
        status={values.status}
        handleChange={(value) => {
          setFieldValue("status", value);
        }}
      />
      <DueDate
        dueDate={values.dueDate}
        dueTime={values.dueTime}
        onChangeDateHandler={(value) => {
          setFieldValue("dueDate", value?.toISOString() ?? null);
        }}
        onChangeTimeHandler={(value) => {
          setFieldValue("dueTime", value?.toISOString() ?? null);
        }}
      />
      <Link
        setFieldValue={setFieldValue}
        linkedUnitId={values.linkedUnitId ?? ""}
        linkedPmsReservationId={values.linkedPmsReservationId}
        linkedDisplayablePmsReservationId={values.linkedDisplayableReservationId ?? ""}
        linkedDepartingPmsReservationId={values.linkedPmsDepartingReservationId ?? ""}
        linkedDisplayableDepartingReservationId={
          values.linkedDisplayableDepartingReservationId ?? ""
        }
        linkedArrivingPmsReservationId={values.linkedPmsArrivingReservationId ?? ""}
        linkedDisplayableArrivingReservationId={values.linkedDisplayableArrivingReservationId ?? ""}
      />
      <Assign
        setFieldValue={setFieldValue}
        assigneeGroup={values.assigneeGroup}
        assignee={values.assignee}
      />
      <PrioritySelect
        onChange={(value) => setFieldValue("priority", value)}
        value={values.priority}
        pmsReservationIds={values.linkedPmsReservationId ? [values.linkedPmsReservationId] : []}
      />
      <Description
        description={values.description ?? ""}
        onChange={(value) => setFieldValue("description", value)}
        isWysiwygVisible={isWysiwygVisible || !!values.description}
        setIsWysiwygVisible={setIsWysiwygVisible}
      />
      <Subtasks subtasks={subtasks} taskId={values.id} />
      <AttachmentsForm
        taskId={values.id}
        setFieldValue={setFieldValue}
        attachments={values.attachments ?? []}
      />
    </Box>
  );
};
