import { FieldWithIcon } from "./field-with-icon";
import { ScheduleOutlined } from "@mui/icons-material";
import { Button } from "@likemagic-tech/sv-magic-library";
import React, { FC, useCallback, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/lab";
import { formatDate, formatTime } from "../../../../utils/timezoned-date";
import { LocalizationDatePicker } from "../../../../components/language-selector/localization-date-picker";
import { useProperty } from "../../../../hooks/use-property";
import { QuickButtonsToolbar } from "./due-modal";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import CloseIcon from "@mui/icons-material/Close";

interface DueDateProps {
  dueDate?: string;
  dueTime?: string;
  onChangeDateHandler: (date: Date | null) => void;
  onChangeTimeHandler: (date: Date | null) => void;
}

export const DueDate: FC<DueDateProps> = ({
  dueTime,
  dueDate,
  onChangeDateHandler,
  onChangeTimeHandler
}) => {
  const { t } = useTranslationWrapper();
  const anchorRef = useRef<any>(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const { selectedProperty } = useProperty();
  const theme = useTheme();

  const QuickButtonsToolbarCallBack = useCallback(
    (props: any) => <QuickButtonsToolbar {...props} onAccept={onChangeDateHandler} />,
    [onChangeDateHandler]
  );

  return (
    <FieldWithIcon icon={<ScheduleOutlined />}>
      <Box sx={{ display: "flex", gap: 1 }} ref={anchorRef}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <LocalizationDatePicker>
            <DatePicker
              onClose={() => setDatePickerOpen(false)}
              open={datePickerOpen}
              disableOpenPicker
              showToolbar
              PopperProps={{
                placement: "auto",
                anchorEl: anchorRef.current
              }}
              value={dueDate || ""}
              onAccept={(date: Date | null) => date && onChangeDateHandler(date)}
              ToolbarComponent={QuickButtonsToolbarCallBack}
              minDate={new Date()}
              renderInput={({ inputRef }) => (
                <Button
                  ref={inputRef}
                  variant="ghost"
                  size="medium"
                  onClick={(event) => {
                    anchorRef.current = event.currentTarget;
                    setDatePickerOpen(true);
                  }}
                >
                  {dueDate
                    ? formatDate(dueDate.toLocaleString(), selectedProperty?.details?.timeZone)
                    : t("labels__task_due_date")}
                </Button>
              )}
              onChange={() => {}}
            />
          </LocalizationDatePicker>
          {dueDate && (
            <CloseIcon
              sx={{ color: theme.palette.text.secondary, cursor: "pointer" }}
              onClick={() => {
                onChangeDateHandler(null);
                onChangeTimeHandler(null);
              }}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <TimePicker
            open={timePickerOpen}
            onClose={() => setTimePickerOpen(false)}
            disableOpenPicker
            PopperProps={{
              placement: "auto",
              anchorEl: anchorRef.current,
              sx: {
                "& span": {
                  color: "inherit"
                }
              }
            }}
            value={dueTime || ""}
            renderInput={({ inputRef }) => (
              <Button
                ref={inputRef}
                variant="ghost"
                size="medium"
                disabled={!dueDate}
                onClick={(event) => {
                  anchorRef.current = event.currentTarget;
                  setTimePickerOpen(true);
                }}
              >
                {dueTime
                  ? formatTime(dueTime.toLocaleString(), selectedProperty?.details.timeZone)
                  : t("labels__task_due_time")}
              </Button>
            )}
            onChange={(time: Date | null) => time && onChangeTimeHandler(time)}
          />
          {dueTime && (
            <CloseIcon
              sx={{ color: theme.palette.text.secondary, cursor: "pointer" }}
              onClick={() => onChangeTimeHandler(null)}
            />
          )}
        </Box>
      </Box>
    </FieldWithIcon>
  );
};
