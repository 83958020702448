import { FC } from "react";
import { TransactionsForProfile } from "../../types/profile";
import { TableCell, TableRow } from "@mui/material";
import { formatDateTime, Paragraph } from "@likemagic-tech/sv-magic-library";

interface ProfileDrawerTransactionTableRowProps {
  item: TransactionsForProfile;
}

export const ProfileDrawerTransactionTableRow: FC<ProfileDrawerTransactionTableRowProps> = ({
  item
}) => {
  return (
    <TableRow hover key={item.pmsId}>
      <TableCell />
      <TableCell>
        <Paragraph>{item.pmsReservationId}</Paragraph>
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{formatDateTime(item.start)}</TableCell>
      <TableCell>
        {item.price.grossPrice} {item.price.currency}
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
